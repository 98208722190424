import '@popperjs/core/dist/esm/popper.js';

import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/tab';

import { Fancybox } from "@fancyapps/ui";
import Swal from 'sweetalert2';

(function($) {

  // Allow Bootstrap tabs to be set by the url path
  $( document ).ready(function() {
    var hash = location.hash.replace(/^#/, ''); // Remove the hash
    if (hash) {
      $('.nav-tabs button[data-bs-target="#' + hash + '"]').tab('show'); // Show the tab
    }
  });

  $( window ).on( 'hashchange', function( e ) {
    var hash = location.hash.replace(/^#/, ''); // Remove the hash
    if (hash) {
      $('.nav-tabs button[data-bs-target="#' + hash + '"]').tab('show'); // Show the tab
    }
  } );

  // Change hash for page-reload
  $('.nav-tabs a').on('shown.bs.tab', function (e) {
    window.location.hash = e.target.hash;
  })

  $('.dropdown-toggle').click(function() {
    $(this).next('.dropdown-menu').slideToggle(500);
  });

  // Wrap tables
  $( document ).ready(function() {
    $( "main table" ).wrap( "<div class='table-responsive'></div>" );
  });

  // Globals scroll to target
  $(document).on('click', '.scroll-to', function (event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
  });

  // Show hide header on scroll
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
    var headerHeight = document.getElementById("header").offsetHeight;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("header").style.top = "0";
    } else {
      document.getElementById("header").style.top = "-" + headerHeight + "px";
    }
    prevScrollpos = currentScrollPos;
  }

  // Add sweetalert2 to Contact Form 7 messages
  document.addEventListener('wpcf7submit', function(event) {

    if (event.detail.status == "validation_failed") {

      Swal.fire({
        text: event.detail.apiResponse.message,
        icon: 'error',
        confirmButtonColor: '#121F3B',
      });

    } else {

      Swal.fire({
        text: event.detail.apiResponse.message,
        icon: 'success',
        confirmButtonColor: '#121F3B',
      });

      // Hide the modals on success
      $('#bookViewing').modal('hide');
      $('#requestCall').modal('hide');

    }
  }, false);

})( jQuery );